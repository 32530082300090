@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.public-page-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0 1.5rem 1.5rem;
  background-color: var(--main-green);
  border-radius: 1rem;
  width: 100%;

  &__avatar__container__hover {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    position: absolute;
    top: 4.5rem;
    left: 2.9rem;

    path {
      stroke: var(--main-white);
    }
  }

  &__left {
    display: flex;
    align-items: center;
    min-width: 800px;

    @media only screen and (width <= 430px) {
      display: flex;
       min-width: 460px
      }

    &__avatar {
      background-color: var(--g-900);
      height: 10.75rem;
      width: 10.75rem;
      border-radius: 50%;
      position: relative;
      cursor: pointer;

      img {
        height: 10.75rem;
        width: 10.75rem;
        object-fit: cover;
        border-radius: 50%;
      }

      &>svg {
        position: absolute;
        top: calc(50% - 26px);
        left: calc(50% - 26px);
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      margin-left: 3rem;
      color: var(--main-black);
      min-width: 570px;
      
      @media only screen and (width <= 430px) {
        display: flex;
         min-width: 430px;
         align-items: center;
         
        }

      h1 {
        @include H4-CL;

        letter-spacing: -0.02em;
      }

      h2 {
        @include BodyS-400;
      }

      &__other {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;

        @include BodyM-400;

        &__twitter {
          display: flex;
          align-items: center;
          gap: 1rem;
          cursor: pointer;

          a {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;
            text-decoration: none;
            color: var(--main-black);

            path {
              fill: black;
            }
          }
        }

        &__wallet {
          display: flex;
          gap: 0.75rem;
          cursor: pointer;
          align-items: center;
        }

        &__joined {
          display: flex;
          gap: 1rem;
        }
      }
    }
    @media only screen and (width <= 430px) {
      flex-direction: column;
      align-items: center;
        }
  }

  &__right {
    align-self: flex-start;
    min-width: 60px;

    &__socials {
      width: 17rem;
      background-color: var(--g-700);
      border-radius: 0.375rem;
      position: absolute;
      top: 40%;
      right: 2%;
      z-index: 4;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.2s ease-out;

      path {
        fill: var(--main-white);
      }

      &.opened {
        display: flex;
        justify-content: space-between;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}