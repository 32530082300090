@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.cancel-offer-warning {
  display: flex;
  flex-direction: column;
  background-color: var(--g-900);
  border-radius: 1rem;
  min-height: 18rem;

  &__icon {
    place-self: center center;
  }

  &__text-block {
    text-align: center;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &__title {
      @include H3;

      color: var(--main-white);
    }

    &__ordinary {
      @include BodyM-400;

      color: var(--g-200);
    }
  }

  &__btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 2rem;
  }

  &__error-block {
    padding-top: 2rem;
    align-self: center;

    .error-block__content {
      flex-direction: row;
    }
  }
}