@import "src/app/styles/fonts";

.footer {
  padding: 0 2rem 0.5rem;
  max-width: 1440px;

  .footer-container {
    background-color: var(--g-900);
    border-radius: 1rem;
    padding: 1.5rem;

    .left-block {
      background-color: var(--g-900);
      border-radius: 1rem;

      h3 {
        @include BodyS-400;

        color: var(--main-white);
      }
    }

    p {
      @include BodyS-400;
      color: var(--g-300);
      cursor: pointer;
    }

    .border {
      margin: 0 1.5rem;
      width: 1px;
      background: var(--g-600);
      height: 1.5rem;
    }

    h4 {
      @include BodyM-XS-600;
      color: var(--main-white);
      text-transform: uppercase;
    }
  }
}

@media screen and (width <= 480px) {

  .footer {
    width: 100vw;
    padding: 0 1rem 0.5rem;
    display: flex;

    .footer-container {
      border-radius: 0.5rem;
      padding: 0.5rem;
      flex-wrap: wrap;

      .left-block {
        width: 100%;
        justify-content: center;
      }

      .right-block {
        width: 100%;
        justify-content: center;
      }

      h4 {
        font-size: 0.69em;
      }
    }
  }
}