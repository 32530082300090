@import "src/app/styles/fonts";

.data-border-card {
  border-color: var(--g-100) !important;
}

.card-row {
  display: grid;
  grid-template-columns: 3fr auto;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid var(--g-700);
  width: 100%;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .data-border {
    border: 1px solid var(--g-100);
    background-color: transparent !important;
  }

  .left-block-wrapper {
    display: grid;
    grid-template-columns: 3fr auto;
    gap: 1rem;
    align-items: center
  }

  .price {
    @include BodyS-600;
    color: var(--main-white);
  }

  .image {
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    background: var(--g-700);
    border-radius: 0.5rem;
    position: relative;

    .listing-status {
      position: absolute;
      top: 0;
      right: -0.425rem;
    }
  }

  .red-border {
    border: 1px solid var(--error-500);
  }

  .info-text {
    align-self: center;
    max-width: 645px;

    .data-name {
      @include BodyM-XS-600;
      font-size: 0.75rem !important;
      line-height: 1.125rem !important;
      color: var(--g-800) !important;
      cursor: unset !important;
    }

    .name {
      @include BodyM-600;
      color: var(--main-white);
      cursor: pointer;
      word-break: break-all;
    }

    .token {
      @include BodyS-600;
      color: var(--g-300);
    }

    .blue-token-text {
      color: var(--b-400);
      cursor: pointer;
    }
  }

  .data-price {
    @include BodyM-XS-600;
    font-size: 0.75rem !important;
    line-height: 1.125rem !important;
    color: var(--g-400) !important;
  }

  .right {

    svg {
      width: 1rem;
      height: 1rem;

      path {
        fill: var(--g-200);
      }

      &:hover {

        path {
          fill: white;
        }
      }
    }
  }


  .button {
    padding: 0.5rem 1rem;
    background-color: var(--error-500);
    text-transform: uppercase;
    border-radius: 1.5rem;
    @include B-Small;
    font-weight: 600;
    cursor: pointer;
  }
}