
.rotate-card {
  height: 100%;
  border-radius: 1rem;
  padding: 2.5rem;
  position: relative;

  &:hover {

    & .front {
      transform: rotateY(180deg);
    }

    & .back {
      transform: rotateY(360deg);
    }
  }

  .front {
    backface-visibility: hidden;
    transition: all 1.5s;
    
    img{
      object-fit: cover;
    }

    .contain {
      object-fit: contain;
    }
  }

  .back {
    position: absolute;
    backface-visibility: hidden;
    transition: all 1.5s;
    transform: rotateY(180deg);
    height: 100%;
    top: 0;
    left: 0;
    padding: inherit;

    img{
      object-fit: cover;
    }

    .contain {
      object-fit: contain;
    }
  }
}

@media screen and (width <= 480px) {

  .rotate-card {
    padding: 1rem;

    &:hover {

      .front, .back {
        transform: none !important;
      }
    }
  }

  .rotate-card.flipped {

    .front {
            transform: rotateY(360deg) !important;
          }
      
          .back {
            transform: rotateY(180deg) !important;
          } 
         }
}