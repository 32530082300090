@import "src/app/styles/fonts";

.shipment-in-progress {

  &__tracking-details {
    padding: 1rem 2rem 2rem;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.125rem;

      width: 100%;

      &__link {
        display: flex;
        align-self: flex-end;
        margin-right: 2rem;
        gap: 0.5rem;

        a {
          @include BodyS-400;

          color: var(--g-100);
          text-decoration: underline;
          letter-spacing: -0.01em;
          display: flex;
          align-self: self-end;
          cursor: pointer;
        }
      }

      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 1rem;
        margin-bottom: 0.5rem;

        &__titles {

          p {
            @include BodyS-600;

            opacity: 0.6;
            width: 60%;
            margin-bottom: 0.75rem;
            color: var(--g-300);
          }

          h2 {
            @include BodyS-600;
          }

          h3 {
            @include BodyS-600;

            margin-top: 0.5rem;
          }
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      gap: 3.5rem;
      justify-content: space-between;
      align-items: flex-end;
      min-width: 11.5rem;
    }
  }

  &__block-description {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    margin: 1.5rem 2rem 2rem;
    background: var(--g-700);
    border-radius: 1rem;
    gap: 2.5rem;

    &__left {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1rem;
      color: var(--main-white);

      h1 {
        @include BodyS-600;

        text-transform: uppercase;
      }

      p {
        @include BodyM-XS-600;
      }
    }
  }

  :target {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }
}