@import "src/app/styles/fonts";

.outbound-shipment {

  &__tracking-details {
    padding: 1rem 2rem 2rem;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      min-height: 13.75rem;

      &__top {
        display: flex;
        gap: 4.5rem;
        padding: 2rem 0 2.5rem;
        margin-right: 2rem;

        span {
          @include BodyS-600;

          color: var(--main-white);
        }

        &__address {
          display: flex;
          flex-direction: row !important;
          gap: 2rem;

          div {
            display: flex;
            flex-direction: column;
          }
        }

        &__delivery {

          .trackingNumber {
            display: flex;
            align-items: center;
            gap: 0.125rem;
          }
        }

        &__delivery > div {
          display: flex;
          justify-content: space-between;
        }

        &__payment {

          &__delivery-separated,
          & > div {
            display: flex;
            justify-content: space-between;
          }

          div {
            display: flex;
            align-items: center;
            gap: 0.125rem;
          }

          &__delivery-separated {

            &__status svg {
              margin-right: 0 !important;
              margin-left: 0.5rem;
            }
          }
        }

        &__payment > div > div > svg {
          margin-right: 0.5rem;
        }

        .total_cost{
          padding-right: 9px;
        }

        .date{
          padding-right: 9px;

        }

        .scrollable-list::-webkit-scrollbar {
          width: 5px; 
        }
        
        .scrollable-list {
          flex-direction: column;
          display: flex;
          max-height: 80px;
          min-width: 100px;
          overflow: hidden auto;
          padding-right: 5px;
          scrollbar-color: #828585;
          scroll-behavior: smooth;
        }
        
        .scrollable-list li {
          margin-bottom: 5px;
          list-style: none;
        }
        
        .transaction-item {
          display: flex;
          align-items: center;    
        }
        
        .transaction-item span {
          text-decoration: none;
          color: var(--b-400);
          font-weight: 600;
          font-size: 0.85rem;
          display: flex;
          width: 100px;
          align-items: center;
        }
        
        .copy-btn {
          border: none;
          background: transparent;
          cursor: pointer;
          padding: 3px;
        }
        
      }

      &__top > div {
        display: flex;
        flex-direction: column;
        min-width: 16.8rem;
      }

      p {
        @include BodyS-600;

        color: var(--g-300);
      }

      &__comment {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding-bottom: 1.5rem;

        &__title,
        &__comment-text,
        &__button {
          @include BodyS-600;
        }

        &__title {
          color: var(--g-300);
        }

        &__comment-text {
          color: var(--main-white);
        }

        &__button {
          color: var(--b-400);
          cursor: pointer;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__invoice {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__separator {
    background-color: var(--main-black);
    height: 0.125rem;
    width: 100%;
  }
}