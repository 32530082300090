@import "src/app/styles/fonts";
@import "src/app/styles/mixinScroll";

.list-card-modal-default {
  display: flex;
  flex-direction: column;
  width: 37.5rem;
  background-color: var(--g-900);
  border-radius: 1rem;
  padding: 2.5rem;

  &__owner-errors-block {
    padding: 2rem 0;
  }

  &__alerts {
    display: flex;
    flex-direction: column;

    &__advise {
      display: flex;
      flex-direction: row;
      gap: 0.375rem;
      padding-top: 0.5rem;

      &__text {
        @include BodyM-XS-600;

        color: var(--g-300);

        &__white {
          color: white;
        }
        display: flex;
        align-items: center;
      }
    }

    &__warning {
      padding: 0.625rem 0 2rem;
    }

    &__error-block {
      align-self: flex-start;
      padding: 0.375rem 0 2rem;

      .error-block__content {
        flex-direction: row;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__card-preview {
      padding-top: 2rem;
    }

    &__confirming {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      padding-top: 2rem;

      &__description {
        @include BodyM-XS-600;

        color: var(--g-200);
      }
    }

    &__close-btn {
      align-self: flex-end;
    }

    &__title {
      @include H4-CL;

      color: var(--main-white);
      align-self: center;
    }
  }

  &__input-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 2.5rem;
    gap: 0.688rem;

    &__label {
      @include BodyS-600;

      color: var(--g-100);
    }
  }

  &__button {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
  }
}