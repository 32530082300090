@import "src/app/styles/fonts";

.headerModal {
  position: fixed;
  transform: translateX(140%);
  top: 91px;
  color: var(--main-white);
  background-color: var(--g-700);
  padding: 0.75rem 0.75rem 0.5rem;
  width: 18.5rem;
  overflow-y: clip;
  box-shadow: 0 24px 48px 16px rgb(0 0 0 / 28%),
    0 8px 20px 0 rgb(0 0 0 / 12%);
  border-radius: 1rem !important;
}

.isNotMagic {
  min-height: 28.125rem !important;
}

.popover {
  min-height: 31.125rem;
  position: relative;
  @include BodyS-400;

  .wallet {
    color: white;
    background-color: var(--g-900);
    border-radius: 0.625rem;
    padding: 1rem;

    .btn {
      width: 7.25rem;
      height: 2rem;
    }

    .icon {

      svg {
        background:
          linear-gradient(150.69deg,
            #2be4ca 13.99%,
            #1bcbb8 20.5%,
            #4d78e4 53.32%,
            #8b61fc 75.3%,
            #ad8fff 89.01%);
        border-radius: 100px;
      }
    }

    p {
      @include BodyS-600;
    }

    p+p {
      color: var(--g-300);
    }

    .info {
      width: 15rem;
      height: auto;
      margin-bottom: 0.25rem;
      padding: 1rem;
      border: 1px solid #3D3D3D;
      border-radius: 12px;
      @include BodyS-600;
    }

    .right {
      cursor: pointer;
      padding-left: 0.35rem;
    }
  }

  .menu-el {
    @include BodyS-600;
    width: 100%;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: var(--main-white);
  }

  .menu-el:hover {
    background-color: var(--g-600);
    border-radius: 0.5rem;
  }

  .shipment {
    margin-bottom: 0.25rem;
    cursor: pointer;

    h3 {
      @include BodyM-XS-600;
      text-transform: uppercase;
      padding: 0.5rem 1rem 0;
      color: var(--g-300);
    }

    &>div {
      padding: 0.125rem 0;

      div {
        fill: #2ef098;
        padding: 0.5rem 1rem;
      }

      div:hover {
        background-color: var(--g-600);
        border-radius: 0.5rem;
      }
    }

    &>div>div:last-child {
      fill: #ff7c01 !important;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: var(--g-900);
    padding: 0 1rem;
  }
}

.blue {
  color: var(--b-400);
}