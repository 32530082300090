@import "src/app/styles/fonts";

.link-card {
  cursor: pointer;
  text-decoration: unset;
  width: 20.6rem;

  .card {
    background: var(--g-900);
    border-radius: 1rem;
    padding: 2.25rem 1rem 0.875rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
    justify-content: space-between;
    height: 100%;

    &__owner {
      min-height: 1.375rem;
      position: absolute;
      top: 1rem;
      left: 1rem;

      &__active {
        display: flex;
        align-items: center;
        padding: 3px 0.375rem;
        gap: 0.125rem;
        width: min-content;
        background: rgb(91 234 255 / 15%);
        border-radius: 0.375rem;
        color: var(--b-400);

        @include Body-Tiny-800;
      }
    }

    .card-panel {
      visibility: hidden;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 1rem 1rem 0 0;
      height: 3rem;
      z-index: 4;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.checked {
        justify-content: flex-start;
        visibility: visible;
        background-color: transparent;
      }

      &.burned {
        visibility: visible;
        border-radius: 1rem 1rem 0 0;
        padding: 0.525rem 0.5rem 0.625rem 1.25rem;
        height: 2.5rem;
        justify-content: flex-end;

        svg {
          fill: var(--o-400);
        }

        &:hover {

          svg {
            fill: var(--o-400);
          }
        }
      }

      &__icons {
        display: flex;
        flex-direction: row;
        padding-right: 0.5rem;
        align-items: center;
        gap: 0.5rem;

        &__element {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2rem;
          width: 2rem;
          border-radius: 0.25rem;

          svg {
            fill: var(--g-500);
          }

          &:hover {
            background-color: var(--g-600);

            svg {
              fill: var(--g-200);
            }
          }
        }
      }

      &__checkbox {
        display: flex;
        padding-left: 1rem;
        align-items: center;
        fill: transparent;
        stroke: transparent;
      }
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 284px;

      img{
        object-fit: contain;
      }

      &__horizontal {
        height: 10.5rem;
      }

      &__vertical {
        height: 17.75rem;
      }
    }

    &__hovers {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;

      .hover {
        opacity: 0;
      }

      &__hover-contetn {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 93%;
        height: 97%;
        display: flex;
        flex-direction: column;
        gap: 22.25rem;
        align-items: flex-end;
        transition: all 0.3s;

        &__actions {
          width: 16.75rem;
          margin: 0 auto;
          height: 2.5rem;
          background: var(--o-400);
          border-radius: 8px 8px 0 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.125rem 0.75rem;

          &__group-1 {
            cursor: pointer;
            display: flex;
            gap: 21px;
          }
        }

        &__checkbox {
          width: 2.75rem;
          height: 2.75rem;
          background: var(--o-400);
          border-radius: 8px;
          padding: 0.75rem;
          color: var(--o-400);
        }

        rect {
          fill: var(--o-400);
        }

        path {
          fill: var(--g-900);
        }
      }
    }

    .buy-now {
      width: 20.6rem;
      display: flex;
      background-color: white;
      bottom: 0;
      left: 0;
      border-radius: 0 0 1rem 1rem !important;
      color: var(--main-black);
      position: absolute;

      &__insurance__wrapper {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        @include BodyM-XS-600;

        padding: 0.875rem 1rem;
        white-space: nowrap;
        border-left: 1px solid var(--g-50);
      }

      &__button {
        @include B-Small;

        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      &:hover {
        background-color: var(--g-100);
      }

      &:active {
        background-color: var(--g-200);

        .buy-now__insurance__wrapper {
          border-left: 1px solid var(--g-300);
        }
      }
    }

    &:hover {

      .card-panel {
        visibility: visible;
      }

      .hover {
        opacity: 1;
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      color: var(--main-white);

      &__listing {
        min-height: 1.375rem;

        &__active {
          display: flex;
          align-items: center;
          padding: 0.125rem 0.375rem;
          gap: 0.125rem;
          width: min-content;
          background: var(--main-white);
          border-radius: 0.375rem;
          color: var(--o-500);

          @include Body-Tiny-800;
        }
      }

      &__name {
        @include BodyM-600;

        letter-spacing: -0.01em;
        width: 100%;
        min-height: 3rem;
        max-height: 3rem;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: box;
        -webkit-box-orient: vertical;
      }

      &__insurance-value {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include BodyM-XS-600;

        padding-top: 0.75rem;

        &__category {
          color: var(--g-400);
        }

        &__insurance {
          display: flex;
          align-items: center;
          gap: 0.25rem;
        }
      }
    }
  }
}

@media screen and (width <= 480px) {

  .nft-image {
    width: 18rem;
    height: auto;
  }
}