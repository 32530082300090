.trigger {
  align-items: center;
  display: flex;
  font-size: .875rem;
  gap: 0.25rem !important;
  padding: 0.375rem 0.5rem;
  background-color: var(--main-black);
  border-radius: 0.5rem;
  color: var(--main-white);
  max-height: 1.75rem !important;
  border: none;
  min-width: auto;
}